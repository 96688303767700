<template>
  <div class="m-3">
    <div class="mb-8">
      <div class="text-h5">Персональные данные</div>
      <v-form v-model="valid" ref="form">
        <v-row class="mb-4">
          <v-col cols="12" md="4">
            <v-text-field
                v-model="form.client.surname"
                label="Фамилия"
                :rules="[RequiredValidator]"
                required
            ></v-text-field>

            <v-text-field
                v-model="form.client.name"
                label="Имя"
                :rules="[RequiredValidator]"
                required
            ></v-text-field>

            <v-text-field
                v-model="form.client.lastname"
                label="Отчество"
                :rules="[RequiredValidator]"
                required
            ></v-text-field>

            <v-text-field
                label="Дата рождения"
                v-model="form.client.birthday"
                :rules="[RequiredValidator]"
                v-maska
                data-maska="##.##.####"
                required
            ></v-text-field>

            <v-text-field
                v-model="form.client.nationality"
                label="Гражданство"
                required
                readonly
            ></v-text-field>

            <v-text-field
                v-model="form.client.phone"
                v-maska
                data-maska="+7(###)### ##-##"
                label="Мобильный телефон"
                readonly
                required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" v-if="form.client.document">
            <v-text-field
                v-model="form.client.document.series"
                :rules="validationRules.passport.series"
                v-maska
                data-maska="####"
                label="Серия паспорта"
                required
            ></v-text-field>

            <v-text-field
                v-model="form.client.document.number"
                :rules="validationRules.passport.number"
                v-maska
                data-maska="######"
                label="Номер паспорта"
                required
            ></v-text-field>

            <v-text-field
                label="Дата выдачи паспорта"
                v-model="form.client.document.issue_date"
                :rules="validationRules.passport.issue_date"
                v-maska
                data-maska="##.##.####"
                required
            ></v-text-field>

            <v-text-field
                label="Кем выдан"
                required
                v-model="form.client.document.issuedBy"
                :rules="validationRules.passport.issued_by"
            ></v-text-field>

            <div style="font-size: 14px">
              Пожалуйста, прикрепите фото паспорта в соответствии с предоставленным
              <v-dialog max-width="900" activator="parent">
                <template #activator="{on}">
                  <a class="color-primary" v-on="on">
                    образцом <v-icon size="13" color="#fe6500">mdi-open-in-new</v-icon>
                  </a>
                </template>

                <v-card>

                  <v-card-text class="pa-5">
                    <v-row>
                      <v-col cols="6">
                        <v-img src="/passport-example.jpg"></v-img>
                      </v-col>
                      <v-col cols="6">
                        <v-img src="/passport-2-example.jpg"></v-img>
                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-card>
              </v-dialog>
            </div>

            <MediaSelect
                class="mt-2"
                label="Копии паспорта"
                v-model="form.client.document.photos"
            >
            </MediaSelect>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
                v-model="license.series"
                v-maska
                data-maska="## ##"
                :rules="validationRules.driver.series"
                label="Серия ВУ"
            ></v-text-field>

            <v-text-field
                v-model="license.number"
                v-maska
                data-maska="######"
                :rules="validationRules.driver.number"
                label="Номер ВУ"
            ></v-text-field>

            <v-text-field
                label="Дата выдачи ВУ"
                :rules="validationRules.driver.issue_date"
                v-model="license.issue_date"
                v-maska
                data-maska="##.##.####"
            ></v-text-field>

            <v-text-field
                label="Кем выдан"
                required
                v-model="license.issuedBy"
                :rules="validationRules.driver.issued_by"
            ></v-text-field>

            <v-text-field
                v-model="form.driver.experience"
                v-maska
                data-maska="####"
                label="Водительский стаж(с какого года)"
                hint="Укажите год из особых отметок ВУ"
                :rules="validationRules.driver.experience"
                persistent-hint
            ></v-text-field>

            <div style="font-size: 14px">
              Пожалуйста, прикрепите фото водительского удостоверения в соответствии с предоставленным
              <v-dialog max-width="900" activator="parent">
                <template #activator="{on}">
                  <a class="color-primary" v-on="on">
                    образцом <v-icon size="13" color="#fe6500">mdi-open-in-new</v-icon>
                  </a>
                </template>

                <v-card>

                  <v-card-text class="pa-5">
                    <v-row>
                      <v-col cols="12">
                        <v-img contain max-height="570px" src="/license-example.jpg"></v-img>
                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-card>
              </v-dialog>

            </div>

            <MediaSelect label="Копии ВУ" v-model="license.photos">
            </MediaSelect>
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col cols="12" md="8">
            <v-autocomplete
                :rules="[RequiredValidator]"
                placeholder="Регион регистрации"
                item-text="title"
                :items="areas"
                v-model="form.client.register_region_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <v-textarea
                v-model="form.client.address"
                label="Адреса места регистрации(как в паспорте)"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-checkbox label="Ознакомлен с правилами обработки" v-model="privacy" :rules="[RequiredValidator]">
              <template v-slot:label>
                <div>
                  Ознакомлен с правилами обработки
                  <a target="_blank" href="/politika.docx" @click.stop>
                    персональных данных
                  </a>
                </div>
              </template>
            </v-checkbox>
          </v-col>

          <v-col cols="12" md="4">
            <p
                class="red--text mb-1"
                v-for="(error, i) in validationErrors"
                :key="i"
            >
              {{ error }}
            </p>
            <v-btn color="primary" :loading="loading" block @click="saveClient">
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <v-row>
      <v-col cols="12" md="6">
        <div class="text-h5">Изменить пароль</div>
        <v-form class="vstack" @submit.prevent="changePassword">
          <v-text-field :rules="[RequiredValidator]" v-model="resetPasswordForm.current"
                        label="Текущий пароль"></v-text-field>
          <v-text-field :rules="[RequiredValidator]" v-model="resetPasswordForm.password"
                        label="Новый пароль"></v-text-field>
          <v-text-field :rules="[RequiredValidator]" v-model="resetPasswordForm.repeat"
                        label="Повторите Новый пароль"></v-text-field>
          <v-btn type="submit" color="primary" class="p-4"> Изменить пароль</v-btn>
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-h5">Площадка по умолчанию</div>
        <side-bar-city></side-bar-city>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SideBarCity from "@/components/layout/SideBar/SideBarCity";
import {
  DriverValidation,
  PassportValidation,
} from "../validation/DriverValidation";
import MediaSelect from "../components/widgets/MediaSelect";
import {createForm} from "@/modules/client/utils/createForm";
import {DistrictService} from "@/modules/client/services/districtService";
import {RequiredValidator} from "@/modules/client/validation/Base";
import {changePassword} from "@/modules/client/services/clientService";

export default {
  name: "ClientSettings",
  data: () => ({
    dialogOpened: false,
    loading: false,
    valid: true,
    privacy: true,
    form: {},
    areas: [],
    validationErrors: [],
    resetPasswordForm: {}
  }),
  components: {
    MediaSelect,
    SideBarCity,
  },
  computed: {
    ...mapGetters("config", ["getSites", "getCityList"]),
    ...mapGetters("user", ["user"]),
    license() {
      return this.form.driver.license;
    },
    validationRules() {
      const someOfLicenseFilled = [
        this.license.series,
        this.license.number,
        this.license.issue_date,
        this.license.issuedBy,
        this.experience,
      ].some(Boolean);

      const someOfPassportFilled = [
        this.form.client.document.series,
        this.form.client.document.number,
        this.form.client.document.issue_date,
        this.form.client.document.issuedBy,
      ].some(Boolean);

      return {
        driver: someOfLicenseFilled ? DriverValidation : [],
        passport: someOfPassportFilled ? PassportValidation : [],
      };
    },
  },
  methods: {
    RequiredValidator,
    ...mapActions("user", ["save"]),
    ...mapActions("popup", ["error"]),
    saveClient() {
      this.validationErrors = [];
      if (this.$refs.form.validate() === false) {
        this.validationErrors = this.$refs.form.inputs
            .map((p) => ({
              name: p.label || p.placeholder,
              valid: p.valid,
              errors: p.errorBucket,
            }))
            .filter((p) => p.errors.length)
            .map((p) => `${p.name}: ${p.errors[0]}`);

        return;
      }

      const {client, driver} = this.form;

      if (client.document.series && client.document.photos.length === 0) {
        this.validationErrors.push('Загрузите копии паспорта')
        return
      }

      if (driver.license.series && driver.license.photos.length === 0) {
        this.validationErrors.push('Загрузите копии ВУ')
        return
      }

      this.loading = true;


      try {
        this.save({
          form: createForm({
            client,
            driver,
            document: client.document,
            license: driver.license,
          }).toIso({
            "client.birthday": "",
            "document.issue_date": "",
            "license.issue_date": "",
          }).form,
          id: this.user.client.id,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    async changePassword() {
      try {
        const response = await changePassword(this.resetPasswordForm)
        console.log(response)
      } catch (e) {
        this.error(e.response.data.message)
      }

    }
  },
  async beforeMount() {
    this.form = createForm({
      client: this.user.client,
      driver: this.user.main_driver,
    })
        .setDefault({
          "client.nationality": "РФ",
          "client.phone": this.user.phone,
          "client.document.photos": [],
          "driver.license.photos": [],
        })
        .formatDates({
          "client.birthday": "",
          "client.document.issue_date": "",
          "driver.license.issue_date": "",
        }).form;

    this.areas = (await DistrictService.index()).flatMap((district) =>
        district.areas.map((area) => ({title: area.name, value: area.id}))
    );
  },
};
</script>

<style>
.file-preview-wrapper-image .file-preview .file-size {
  display: none;
}
</style>
